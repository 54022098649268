@media only screen and (max-width: 1440px) and (max-height: 1024px) {
  /* Your styles for the screen resolution of 1440 pixels go here */


  .ordersummary-component {
    .cards {
      height: 22.32vh !important;
    }
  }

  .plan-component {
    .card-container {
      .cards {
        height: 22.5vh !important;
      }
    }

    .for-all-card-container {
      .cards {
        height: 22vh !important;
      }
    }
  }

  .wallet-component {
    min-height: 80vh !important;
    max-height: 80vh !important;

    .table-container {
      max-height: 53vh !important;
      min-height: 53vh !important;
    }

    .summary-table {
      top: 12rem !important;
    }

    .filters {
      margin-top: 2.5rem !important;
    }
  }

  .subscribers-component {
    .t-grid-table-new {
      min-height: 71vh !important;
      max-height: 71vh !important;
    }

    .dropdown-text {
      font-size: 1.1vw;
    }
  }

  .traveller-component {
    .table-b-head {
      height: 50px !important;
    }

    .traveller-input {
      font-size: 12px !important;
    }

    .text-danger {
      font-size: 12px !important;
    }
  }

  .purchase-history-module-component {
    .radio-title {
      font-size: 14px !important;
    }

    input[type='radio'] {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .reports-widget {
    .card {
      &.padding {
        &-mid {
          height: 23.55vw !important;
        }

        &-large {
          height: 20.072vw !important;
        }
      }
    }
  }

  .sub-individual {
    .subinfo {
      .cardSection {
        .greyCard {
          min-height: 25vh !important;
        }
      }
    }
  }

  .country-report {
    margin-bottom: 1.5vw !important;
  }

  .country-report:first-child {
    margin-top: 2vw !important;
  }

}

@media only screen and (max-width: 1440px) and (max-height: 900px) {
  /* Your styles for the screen resolution of 1440 pixels go here */

  ::ng-deep {
    .ng-search {
      margin-top: 0.4rem !important;
    }
  }

  .ordersummary-component {
    .cards {
      height: 25vh !important;
    }
  }

  .plan-component {
    .card-container {
      .cards {
        height: 25vh !important;
      }
    }

    .for-all-card-container {
      .cards {
        height: 25vh !important;
      }
    }
  }

  .destination-component {
    .flex-container {
      max-height: 52vh !important;
      min-height: 52vh !important;
    }
  }


  .wallet-component {
    min-height: 78.75vh !important;
    max-height: 78.75vh !important;

    .table-container {
      max-height: 49vh !important;
      min-height: 49vh !important;
    }

    .summary-table {
      top: 12rem !important;
    }

    .filters {
      margin-top: 2.5rem !important;
    }
  }

    .subscribers-component {

    .dropdown-text {
      font-size: 1vw;
    }
  }

  .sub-individual {
    .subinfo {
      .cardSection {
        .greyCard {
          min-height: 25vh !important;
        }
      }
    }
  }

  .traveller-component {
    .table-b-head {
      height: 52px !important;
    }

    .traveller-input {
      font-size: 12px !important;
    }

    .text-danger {
      font-size: 12px !important;
    }
  }


  .purchase-history-module-component {
    .radio-title {
      font-size: 14px !important;
    }

    input[type='radio'] {
      width: 20px !important;
      height: 20px !important;
    }

    .sub-head {
      .filter-head {
        .lang-dropdown {
          .dropdown-text {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .reports-widget {
    .card {
      &.padding {
        &-mid {
          height: 23.55vw !important;
        }

        &-large {
          height: 20.072vw !important;
        }
      }
    }
  }

  .country-report {
    margin-bottom: 1.5vw !important;
  }

  .country-report:first-child {
    margin-top: 2vw !important;
  }

}
