.ngx-pagination {
  display: flex;
  margin-top: 15px;
  align-items: center;
  gap: 7px;
  padding-left: 0;

  @media screen and (max-width: 1199px) {
    gap: 5px;
  }

  li {
    width: 31px;
    height: 40px;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1199px) {
      width: 28px;
      height: 33px;
      font-size: 14px;
    }

    &.small-screen {
      display: none !important;
    }

    &.pagination-previous a {
      background-color: var(--color-primary-button);
      mask-repeat: no-repeat;
      mask-image: url('/assets/images/dashboard/icon/paginate-arrow-previous.svg');
      -webkit-mask-image: url('/assets/images/dashboard/icon/paginate-arrow-previous.svg');
      margin-left: -10px !important;
      width: 28px;
      height: 28px;
    }

    &.pagination-previous.disabled {
      content: '';
      background-color: var(--color-primary-button);
      mask-repeat: no-repeat;
      mask-image: url('/assets/images/dashboard/icon/paginate-arrow-previous.svg');
      -webkit-mask-image: url('/assets/images/dashboard/icon/paginate-arrow-previous.svg');
      margin-left: 4px !important;
      width: 28px;
      height: 28px;
      opacity: 0.5;
    }

    &.pagination-next a {
      mask-image: url('/assets/images/dashboard/icon/paginate-arrow-next.svg');
      -webkit-mask-image: url('/assets/images/dashboard/icon/paginate-arrow-next.svg');
      mask-repeat: no-repeat;
      background-color: var(--color-primary-button);
      margin-left: 0 !important;
      width: 28px;
      height: 28px;
    }

    &.pagination-next.disabled {
      mask-image: url('/assets/images/dashboard/icon/paginate-arrow-next.svg');
      -webkit-mask-image: url('/assets/images/dashboard/icon/paginate-arrow-next.svg');
      mask-repeat: no-repeat;
      background-color: var(--color-primary-button);
      margin-left: 0px !important;
      width: 28px;
      height: 28px;
      opacity: 0.5;
    }

    &.pagination-next a::after,
    &.pagination-previous a::before {
      content: '';
    }

    &.pagination-next a:hover,
    &.pagination-previous a:hover {
      background-color: var(--color-primary-button);
    }

    &.pagination-next>a {
      padding-left: 0px !important;
    }

    &.pagination-previous,
    &.pagination-next {

      a,
      span {
        border-radius: 35%;
        overflow: hidden;
        color: transparent;
      }
    }

    a:hover,
    button:hover {
      background: transparent;
      color: var(--pagination-font-color) !important;
    }
  }
}

.ngx-pagination .pagination-previous.disabled:before,
.ngx-pagination .pagination-next.disabled:after {
  opacity: 0.4;
}

.ngx-pagination a,
.ngx-pagination button {
  color: var(--table-text-color) !important;
  text-decoration: none !important;
}

.ngx-pagination li:not(.current):not(.pagination-previous):not(.pagination-next) {
  border: 1px solid rgba(99, 101, 239, 0.17) !important;
  background: var(--color-white);
  color: var(--color-primary-font);
}

.ngx-pagination li.current,
.ngx-pagination li:not(.current):not(.pagination-previous):not(.pagination-next):hover {
  background: var(--color-primary-button) !important;
  color: var(--color-white) !important;
}