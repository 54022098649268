.circles-parent {
  position: absolute;
  inset: 0;
}

.solid-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.solid-circle-grey {
  background: rgba(255, 255, 255, 0.3);
}

.common-circle-grey {
  border: 20px solid rgba(255, 255, 255, 0.3);
}

.circle0 {
  top: 0;
  position: absolute;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  opacity: 0.15;
  transform: translate(-50%, -50%);
}

.circle1 {
  top: 0;
  position: absolute;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}

.circle2 {
  top: 0;
  position: absolute;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}

.circle3 {
  top: 0;
  position: absolute;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.common-circle {
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.common-circle-1 {
  animation: growAndFade 5s infinite ease-out;
}

@keyframes growAndFade {
  0% {
    opacity: 0;
  }

  30% {
    opacity: var(--ripple-opacity);
  }

  100% {
    opacity: 0;
  }
}

.accordion {
  &-button {
    padding: 9px 12px !important;

    &:not(.collapsed) {
      color: var(--color-white) !important;
    }
  }
}

.country-options {
  color: var(--color-primary-font);
  font-family: var(--font-plus-jakarta-sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: wrap;
  gap: 1rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  @media screen and (max-width: 1399px) {
    font-size: 14px;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  &:hover {
    background: var(--color-dark-disabled);
    border-radius: 10px;

    @media screen and (max-width: 1399px) {
      border-radius: 6px;
    }
  }
}

.flag-style-parent {
  width: 32px;
  height: 22px;
  display: flex;

  @media screen and (max-width: 991px) {
    width: 19px;
    height: 14px;
    margin-top: 0.25rem;
  }

  @media screen and (max-width: 575px) {
    width: 30px;
    height: 22px;
  }
}

.flag-style {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7.14px !important;
  margin-top: 0.05rem;

  @media screen and (max-width: 575px) {
    border-radius: 4px !important;
  }
}

.back-arrow {
  width: 90%;
  margin: auto;

  .back-arrow-svg {
    width: 40px;
    height: 40px;
  }
}

// Travel App CSS
.btn58 {
  height: 58px !important;
}

.common-header {
  margin-top: 4.5rem !important;
  padding-right: 1rem !important;
}

.thanks-modal-outer-div {
  text-align: center;
  margin: auto;

  .modal-common-circle {
    border-radius: 50%;

    /* Set a higher z-index to place it above the SVG */
    &.outer-circle {
      height: 7rem;
      width: 7rem;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      background-color: #ef63762d;
    }

    &.inner-circle {
      height: 5rem;
      width: 5rem;
      margin: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(205deg, var(--color-danger) 7.2%, rgba(99, 101, 239, 0) 86.13%);
    }
  }

  .svg-container {
    position: relative;
    width: 100%;
    height: 0;
    z-index: 1;
  }

  .svg-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .success-icon {
    margin-top: -5rem;

    .outer-circle {
      background-color: var(--color-primary);
    }

    .inner-circle {
      background: linear-gradient(205deg, var(--color-primary) 7.2%, rgba(99, 101, 239, 0) 86.13%);
    }
  }

  .failed-icon {
    .outer-circle {
      background-color: #ef63762d;
    }

    .inner-circle {
      background: linear-gradient(205deg, var(--color-danger) 7.2%, rgba(99, 101, 239, 0) 86.13%);
    }
  }

  .thanks-modal-inner-div {
    width: 90%;
    text-align: center;
    margin: auto;
    padding: 20px;

    @media screen and (max-width: 1399px) {
      padding: 20px 0;
    }

    .heading {
      color: var(--color-primary-font);
      text-align: center;
      font-family: var(--font-plus-jakarta-sans);
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: -0.56px;

      @media screen and (max-width: 1399px) {
        font-size: 26px;
      }

      @media screen and (max-width: 1199px) {
        font-size: 24px;
      }

      @media screen and (max-width: 575px) {
        font-size: 20px;
      }
    }

    .sub-heading {
      color: var(--color-primary-font);
      text-align: center;
      font-family: var(--font-plus-jakarta-sans);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;

      @media screen and (max-width: 1199px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1199px) and (min-height: 1000px) {
        font-size: 16px;
      }

      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
    }

    .sub-heading-2 {
      color: var(--color-primary-font);
      text-align: center;
      font-family: var(--font-plus-jakarta-sans);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.2px;
      width: 95%;

      @media screen and (max-width: 1199px) {
        font-size: 14px;
        line-height: 23px;
      }

      @media screen and (max-width: 1199px) and (min-height: 1000px) {
        font-size: 16px;
      }
    }

    .description {
      color: var(--color-primary-font);
      font-family: var(--font-plus-jakarta-sans);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      text-align: start !important;
      margin-top: 5rem;
      opacity: 0.61;

      @media screen and (max-width: 1399px) {
        margin-top: 3rem;
        font-size: 14px;
        text-align: center !important;
      }

      @media screen and (max-width: 1199px) {
        margin-top: 1.5rem;
        line-height: 23px;
      }
    }
  }

  .btn-setting {
    border-radius: 100px;
    background: var(--color-primary-button);
    display: flex;
    padding: 12px 0px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    color: var(--color-white);
    text-align: center;
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.5px;

    @media screen and (max-width: 1399px) {
      font-size: 20px;
      padding: 8px 0;
    }

    @media screen and (max-width: 1199px) {
      height: 40px;
      font-size: 16px;
      font-weight: 400;
    }

    @media screen and (max-width: 1199px) and (min-height: 1000px) {
      font-size: 20px;
    }

    @media screen and (max-width: 575px) {
      height: 36px;
    }

    &:active {
      background: var(--primary-main, var(--color-primary));
      color: var(--color-white);
      border: 0;
    }
  }
}

.success-modal {
  .card {
    border-radius: 20px !important;

    &.shadow-card {
      height: 30px;
      width: 95%;
      opacity: 0.7;
      margin: auto;
      margin-bottom: -20px;
    }
  }

  .modal-content {
    border-radius: 20px !important;
    background-color: transparent !important;
    border: none;
  }
}

.success-svg {
  .circle-svg-primary {
    fill: var(--color-primary);
  }

  .stop-svg-primary {
    stop-color: var(--color-primary);
  }
}

.modal-sm {
  --bs-modal-width: 400px; // for customer add/edit, plan info, subscriber info modals

  .modal-dialog.bg-transparent {
    --modal-background-color: transparent;
  }

  .modal-content {
    border: none !important;
    border-radius: 8px !important;

    .es-modal {
      .modal-header-flex {
        display: flex;
        justify-content: space-between;
      }

      .modal-header-text {
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 700;
        font-size: 18px;
        font-style: normal;
        line-height: 20px;
      }

      .modal-sub-header-text {
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        opacity: 0.61;

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          font-size: 16px;
        }

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }

      .btn-danger {
        color: var(--color-white);
        border: 1px solid var(--color-danger) !important;
        background: var(--color-danger) !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
      }

      .btn-secondary {
        color: var(--color-secondary-button-text);
        border: 1px solid var(--color-dark-disabled) !important;
        background: var(--color-secondary-button) !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
      }
    }
  }

  .has-backdrop {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
  }
}

.modal-sms {
  --bs-modal-width: 400px; // for customer add/edit, plan info, subscriber info modals

  .modal-dialog.bg-transparent {
    --modal-background-color: transparent !important;
  }

  .modal-content {
    border: none !important;
    border-radius: 8px !important;
    background-color: transparent;

    .es-modal {
      .modal-header-flex {
        display: flex;
        justify-content: space-between;
        height: 4.5rem;
      }

      .modal-header-text {
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .modal-sub-header-text {
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .btn-danger {
        color: var(--color-white);
        font-family: var(--font-plus-jakarta-sans) !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid var(--color-danger) !important;
        background: var(--color-danger) !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .btn-secondary {
        color: var(--color-secondary-button-text);
        font-family: var(--font-plus-jakarta-sans) !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid var(--color-dark-disabled) !important;
        background: var(----color-secondary-button) !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }

  .has-backdrop {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
  }
}

.modal-addFunds {
  --bs-modal-width: 559px; // for customer add/edit, plan info, subscriber info modals

  .modal-dialog.bg-transparent {
    --modal-background-color: transparent !important;
  }

  .modal-content {
    border: none !important;
    border-radius: 8px !important;
    background-color: transparent;

    .es-modal {
      .modal-header-flex {
        display: flex;
        justify-content: space-between;
        height: 4.5rem;
      }

      .modal-header-text {
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .modal-sub-header-text {
        color: var(--color-secondary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .btn-danger {
        color: var(--color-white);
        font-family: var(--font-plus-jakarta-sans) !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid var(--color-danger) !important;
        background: var(--color-danger) !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .btn-secondary {
        color: var(--color-secondary-button-text);
        font-family: var(--font-plus-jakarta-sans) !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid var(--color-dark-disabled) !important;
        background: var(--color-secondary-button) !important;

        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }

  .has-backdrop {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
  }
}

.invoice-download {
  --bs-modal-width: 595px;
  display: flex;
  align-items: flex-start;
  height: 90vh;
  overflow-y: auto;

  .modal-content {
    overflow-x: none;
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent;
  }

  .has-backdrop {
    background: rgba(0, 0, 0, 0.7);
  }
}

// Custom Scroolbar

::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: color-mix(in lch, var(--color-primary) 20%, transparent);
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 100px;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.height-mob {
  @media screen and (max-width: 575px) {
    height: 100vh;
  }
}

.heading-icon-container {
  gap: 1rem;
  margin-bottom: 1rem;

  .heading-icon {
    width: 24px;
    height: 24px;
    display: flex;

    @media screen and (max-width: 991px) {
      width: 18px;
      height: 18px;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        fill: var(--color-primary);
      }
    }
  }

  .heading {
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 700;
    font-size: 24px;
    color: var(--color-primary);
    margin-bottom: 0;

    @media screen and (max-width: 1679px) {
      font-size: 22px;
    }

    @media screen and (max-width: 1199px) {
      font-size: 16px;
    }

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
}

.basic-info-div {
  .input-row {

    .left-col,
    .right-col {
      min-height: 7rem;

      @media screen and (max-width: 1679px) {
        min-height: 5.75rem;
      }

      @media screen and (max-width: 991px) {
        min-height: 4.25rem;
      }

      @media screen and (max-width: 575px) {
        min-height: 6rem;
      }
    }

    .mob-right-col {
      @media screen and (max-width: 575px) {
        min-height: 6rem;
      }
    }
  }

  .input-field-parent {
    .form-label {
      font-family: var(--font-plus-jakarta-sans);
      font-weight: 500;
      font-size: 16px;
      color: var(--color-primary-font);

      @media screen and (max-width: 1199px) {
        font-size: 14px;
      }

      @media screen and (max-width: 991px) {
        font-size: 12px;
      }

      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
    }

    .form-control {
      height: 45px;
      border-radius: 6px;
      border: 2px solid var(--color-dark-disabled);
      background: transparent;
      color: var(--color-primary-font);
      opacity: 0.8;
      font-family: var(--font-plus-jakarta-sans);
      font-weight: 400;

      @media screen and (max-width: 1679px) {
        height: 40px;
      }

      @media screen and (max-width: 1199px) {
        height: 38px;
        font-size: 14px;
      }

      @media screen and (max-width: 991px) {
        height: 28px;
        font-size: 12px;
      }

      @media screen and (max-width: 575px) {
        height: 44px;
        font-size: 14px;
        border-radius: 10px;
      }
    }

    .mobile-number-input {
      position: relative;

      .mobile-number-border {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;

        .dropdown {
          .mobile-selection {
            background: transparent;
            border: none;
            width: 4.5rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1.5px solid var(--color-dark-disabled);
            color: var(--color-primary-font);
            opacity: 0.8;
            font-family: var(--font-plus-jakarta-sans);
            font-weight: 400;

            @media screen and (max-width: 1199px) {
              font-size: 14px;
            }

            @media screen and (max-width: 991px) {
              font-size: 12px;
            }

            @media screen and (max-width: 575px) {
              font-size: 14px;
            }

            &::after {
              content: '';
              width: 0.75rem;
              height: 0.5rem;
              background: url('/assets/images/auth/arrow-dropdown.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: auto;
              border: none;
            }
          }

          .dropdown-menu {
            width: 32.25rem;
            height: 19rem;
            overflow-y: auto;
            overflow-x: hidden;
            inset: 1rem auto 0 auto !important;
            padding: 0.5rem;

            @media screen and (max-width: 1679px) {
              width: 21.75rem;
            }

            @media screen and (max-width: 1199px) {
              width: 17.5rem;
            }

            @media screen and (max-width: 991px) {
              width: 12.75rem;
            }

            .country-search-box {
              position: relative;

              .search-icon-div {
                position: absolute;
                left: 4%;
                top: 50%;
                transform: translateY(-50%);

                @media screen and (max-width: 1399px) {
                  width: 14px;
                  height: 14px;
                }

                @media screen and (max-width: 575px) {
                  left: 5%;
                }

                svg {
                  vertical-align: middle;
                  vertical-align: -webkit-baseline-middle;
                }
              }
            }
          }
        }
      }

      input {
        padding-left: 5.5rem;
      }
    }
  }

  .edit-button {
    width: 100%;
    background-color: var(--color-primary-button);
    height: 44px;
    border-radius: 100px;
    color: var(--color-white);
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 400;
    margin-top: 2rem;
    font-size: 16px;

    @media screen and (max-width: 1679px) {
      margin-top: 1.5rem;
    }

    @media screen and (max-width: 1199px) {
      height: 36px;
      font-size: 12px;
    }

    @media screen and (max-width: 991px) {
      height: 28px;
    }

    @media screen and (max-width: 575px) {
      height: 44px;
      font-size: 18px;
    }

    &:active {
      background-color: var(--color-primary-button);
      border-color: var(--color-primary-button);
      color: var(--color-white);
    }
  }
}

.basic-info-mobile {
  .mobile-number-input {
    input {
      background-color: var(--color-dark-disabled) !important;
    }
  }
}

.setting-action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media screen and (max-width: 1679px) {
    margin-top: 1.5rem;
  }

  @media screen and (max-width: 1199px) {
    margin-right: 0rem;
  }

  @media screen and (max-width: 575px) {
    gap: 1.5rem;
    flex-direction: column-reverse;
  }

  .setting-cancel-btn {
    border-radius: 100px;
    background: var(--color-dark-disabled);
    display: flex;
    width: 240px;
    justify-content: center;
    align-items: center;
    color: var(--color-primary-font);
    text-align: center;
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-right: 16px;
    height: 44px;

    @media screen and (max-width: 1399px) {
      font-size: 16px;
      height: 44px;
      width: 140px;
    }

    @media screen and (max-width: 1199px) {
      height: 36px;
      font-size: 12px;
    }

    @media screen and (max-width: 991px) {
      height: 32px;
      width: 110px;
      margin-right: 10px;
    }

    @media screen and (max-width: 575px) {
      height: 44px;
      font-size: 18px;
      width: 100%;
      margin-right: 0;
    }

    &:active {
      background: var(--color-dark-disabled);
      border-color: var(--color-dark-disabled);
      color: var(--color-primary-font);
    }
  }

  .setting-changes-btn {
    border-radius: 100px;
    background: var(--color-primary-button);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    height: 44px;
    width: 250px;

    @media screen and (max-width: 1399px) {
      font-size: 16px;
      height: 44px;
      width: 140px;
    }

    @media screen and (max-width: 1199px) {
      height: 36px;
      font-size: 12px;
    }

    @media screen and (max-width: 991px) {
      height: 32px;
      width: 110px;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (max-width: 575px) {
      height: 44px;
      font-size: 18px;
      width: 100%;
      margin-right: 0;
    }

    &:active {
      background: var(--color-primary-button);
      border-color: var(--color-primary-button);
      color: var(--color-white);
    }
  }
}

.connectivity-currency-fields {
  .input-field-parent {
    .form-control {
      background: var(--color-dark-disabled);

      &::placeholder {
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 500;
      }
    }

    .currency-input-parent {
      .currency-dropdown {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        border-right: 2px solid var(--color-dark-disabled);
        padding-right: 0.5rem;
        color: var(--color-primary-font);
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 500;
        opacity: 0.8;

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }

      input {
        padding-left: 6rem;
      }
    }
  }
}