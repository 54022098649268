$background_color: #141414;
$border-color_1: black transparent transparent transparent;
$border-color_2: transparent transparent black transparent;
$border-color_3: transparent transparent transparent black;
$border-color_4: transparent black transparent transparent;

.tooltip-example {
  text-align: center;
  padding: 0 50px;

  [tooltip] {
    display: inline-block;
    margin: 50px 20px;
    width: 180px;
    height: 50px;
    border: 1px solid gray;
    border-radius: 5px;
    line-height: 50px;
    text-align: center;
    word-break: break-all;
  }
}

.ng-tooltip {
  position: absolute;
  max-width: 20vw;
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 9px 25px;
  background: $background_color;
  border-radius: 25px;
  z-index: 9999;
  opacity: 0;
  word-break: break-all;

  @media screen and (max-width: 1199px) {
    max-width: 30vw;
    font-size: 12px;
    padding: 9px;
  }

  @media screen and (max-width: 991px) {
    max-width: 35vw;
    padding: 5px 8px;
    font-size: 11px;
  }

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
  }

  &-light {
    background: #ebebeb !important;
    color: #383737 !important;
    border-radius: 14px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;
    width: 18vw;
    word-break: break-all;
  }

  &-right {
    &:after {
      top: 0 !important;
      right: 0 !important;
      margin-top: 0 !important;
      border-width: 0 !important;
    }
  }
}

.ng-tooltip-top {
  &:after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: $border-color_1;
  }
}

.ng-tooltip-bottom {
  &:after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: $border-color_2;
  }
}

.ng-tooltip-left {
  &:after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: $border-color_3;
  }
}

.ng-tooltip-show {
  opacity: 1;
}

.download-tooltip {
  padding: 9px 15px !important;
}

/* Inactive Plan Tooltip CSS - End */