// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gb-travel-app-fe-primary: mat.define-palette(mat.$indigo-palette);
$gb-travel-app-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gb-travel-app-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gb-travel-app-fe-theme: mat.define-light-theme((color: (primary: $gb-travel-app-fe-primary,
        accent: $gb-travel-app-fe-accent,
        warn: $gb-travel-app-fe-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gb-travel-app-fe-theme);

/*
   Font Import Notice:
   This file imports 'Plus Jakarta Sans' from Google Fonts.
   - Ensure used font weights/styles only.
   - Optimize 'display=swap' for better text loading.
*/
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/functions';

@import './assets/scss/authentcation';
@import './assets/scss/common';
@import './assets/scss/pagination';
@import './assets/scss/tooltip';
@import './assets/scss/macbook-1440';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import './assets/scss/color';
@import './assets/scss/variable';
@import './assets/scss/lock-screen';

$colorDark: #303030;
$colorDarkRgb: #{red($colorDark)},
  #{green($colorDark)},
  #{blue($colorDark)};
$colorShadow: #707070;
$colorShadowRgb: #{red($colorShadow)},
  #{green($colorShadow)},
  #{blue($colorShadow)};

:root {
  // Color Roots According to CRM
  --color-primary: #6365ef;
  --color-secondary: #f0f0fe;
  --color-primary-font: #303030;
  --color-secondary-font: #686868;
  --color-primary-button: #6365ef;
  --color-secondary-button-text: #6365ef;
  --color-background: #f0f0fe;
  --color-navbar: #ffffff;

  // Color Roots Based on Color Palette
  --color-dark-disabled: rgba(#{$colorDarkRgb}, 0.12);
  --color-text-disabled: rgba(#{$colorDarkRgb}, 0.61);
  --color-secondary-button: #ffffff;

  // Default Centric Color
  --color-success: #2d9563;
  --color-warning: #7f8d26;
  --color-danger: #d32f2f;
  --color-white: #ffffff;
  --color-pending: #F1BF62;

  --color-shadow: #{$colorShadowRgb};

  // For only graphs
  --color-primary-10: color-mix(in srgb, var(--color-primary) 10%, transparent);
  --color-primary-16: color-mix(in srgb, var(--color-primary) 16%, transparent);
  --color-primary-0: color-mix(in srgb, var(--color-primary) 0%, transparent);

  // Font Family  -  Plus Jakarta Sans
  --font-plus-jakarta-sans: 'Plus Jakarta Sans', sans-serif;

  .search-form .form-control,
  .nav-item.dropdown .dropdown-toggle,
  .switch,
  .btn-dashboard-head {
    box-shadow: none;
  }

  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      background: url(/assets/icons/down-caret-black.svg) no-repeat;
    }
  }
}

$theme-colors: (
  primary: var(--color-primary),
  secondary: var(--color-dark-disabled),
);

.table {
  --bs-table-bg: transperent !important;
}

body {
  overflow: hidden;

  @media screen and (max-width: 767px) {
    overflow: unset;
  }

  .modal-sm {
    --bs-modal-width: 400px; // for customer add/edit, plan info, subscriber info modals
  }

  .modal-vw-35 {
    --bs-modal-width: 35vw; // for customer add/edit, plan info, subscriber info modals
  }

  .modal-vw-40 {
    --bs-modal-width: 40vw; // for customer add/edit, plan info, subscriber info modals
  }
}

textarea:focus,
input:focus,
.form-control:focus,
.btn:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-danger {
  color: var(--color-danger) !important;
  font-family: var(--font-plus-jakarta-sans);
}

.text-uderline {
  text-decoration: underline !important;
}

.custom {
  background-color: #428eff !important;
  min-width: 1440px !important;
}

.appRoot {
  width: var(--bs-breakpoint-xxl);
}