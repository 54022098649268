.t-color-primary {
  color: var(--color-primary);
}

.t-bg-primary {
  background: var(--color-primary);
}

.btn-primary {
  background-color: var(--color-primary-button) !important;
}

.color-white {
  color: var(--color-white) !important;
}