.main-section {
  height: 100vh;
  position: relative;
  padding: 2rem 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1399px) {
    display: block;
    padding: 2rem 0 0;
  }

  @media screen and (max-width: 1199px) and (min-height: 1000px) {
    padding: 5.125rem 0 0;
  }

  @media screen and (max-width: 991px) {
    padding: 1rem 0 0;
  }

  @media screen and (max-width: 767px) {
    padding: 2rem 0;
    height: 100%;
    min-height: 100vh;
  }

  @media screen and (max-width: 575px) {
    padding-right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--color-primary);
    opacity: 20%;
  }

  .main-section-inner {
    position: relative;
    z-index: 12;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1190px;

    @media screen and (max-width: 1199px) {
      max-width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1199px) and (min-height: 1000px) {
      height: 100%;
    }

    &.sign-in-inner {
      padding: 0 5.5rem;
    }
  }

  .main-common-ui {
    background: var(--color-primary);
    padding: 2.5rem 2.25rem 3rem 3rem;
    height: 100%;
    border-radius: 40px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;


    @media screen and (max-width: 1399px) {
      padding: 1.5rem 1rem 1.5rem 1.5rem;
      border-radius: 30px;
    }

    @media screen and (max-width: 1199px) and (min-height: 1000px) {
      padding: 2.25rem 1rem 1.5rem 1.5rem;
      border-radius: 36px;
    }

    @media screen and (max-width: 991px) {
      padding: 1.25rem 1rem 1.5rem 1.5rem;
      border-radius: 30px;
    }

    @media screen and (max-width: 767px) {
      border-radius: 26px;
    }

    .img-1 {
      .go-home-btn {
        background-color: var(--color-primary-button);
        color: var(--color-white);
        border-radius: 30px;
        padding: 0.3vw 1.5vw;
        display: flex;
        align-items: center;

        &:active {
          border-color: var(--color-primary);
        }
      }
    }

    .common-content-left-div {
      color: var(--color-white);
      margin-top: 2.5rem;

      @media screen and (max-width: 1399px) {
        margin-top: 1.75rem;
      }

      @media screen and (max-width: 1199px) and (min-height: 1000px) {
        margin-top: 2.25rem;
      }

      .heading-div {
        @media screen and (max-width: 1399px) {
          padding-right: 1rem;
        }

        h2 {
          font-size: 24px;
          font-family: var(--font-plus-jakarta-sans);
          font-weight: 600;

          @media screen and (max-width: 1399px) {
            font-size: 18px;
          }

          @media screen and (max-width: 1199px) and (min-height: 1000px) {
            font-size: 24px;
          }

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }

          @media screen and (max-width: 767px) {
            font-size: 20px;
          }

          @media screen and (max-width: 575px) {
            font-size: 18px;
          }
        }
      }

      .steps-heading {
        font-size: 18px;
        line-height: normal;
        margin-top: 2.5rem;
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 500;

        @media screen and (max-width: 1399px) {
          font-size: 14px;
          font-weight: 400;
          margin-top: 1.5rem;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          font-size: 18px;
        }

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }

        @media screen and (max-width: 767px) {
          margin-top: 1rem;
          font-size: 14px;
        }
      }

      .steps-list {
        list-style-type: decimal;
        font-size: 16px;
        gap: 1.75rem;
        display: flex;
        padding-left: 1.5rem;
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 400;

        @media screen and (max-width: 1399px) {
          font-size: 13px;
          gap: 1.25rem;
          padding-left: 1rem;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          gap: 2rem;
          font-size: 16px;
          margin-bottom: 1.5rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 10px;
          gap: 1.25rem;
          margin-bottom: 0.75rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }

      .travel-app-highlight-section {
        margin-top: 2rem;
        padding-right: 0.75rem;

        @media screen and (max-width: 1399px) {
          margin-top: 1rem;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          padding-right: 2.5rem;
        }

        @media screen and (max-width: 991px) {
          padding-right: 1.5rem;
        }

        @media screen and (max-width: 767px) {
          padding-right: 0.5rem;
        }

        ul {
          list-style: none;
          padding-left: 0rem;

          @media screen and (max-width: 767px) {
            height: 7.5rem;
          }

          li {
            position: relative;

            &:not(:last-child) {
              margin-bottom: 2rem;

              @media screen and (max-width: 1399px) {
                margin-bottom: 1rem;
              }

              @media screen and (max-width: 1199px) and (min-height: 1000px) {
                margin-bottom: 1.5rem;
              }
            }

            &::before {
              content: '';
              position: absolute;
              top: 0.15rem;
              left: 0;
              height: 20px;
              width: 20px;
              background: url('/assets/images/auth/list-type.svg');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-position: center center;

              @media screen and (max-width: 1399px) {
                height: 16px;
                width: 16px;
              }
            }

            .highlight-inner-div {
              margin-left: 2.5rem;

              @media screen and (max-width: 1399px) {
                margin-left: 2rem;
              }

              .highlight-heading {
                font-size: 18px;
                margin-bottom: 0.25rem;
                font-family: var(--font-plus-jakarta-sans);
                font-weight: 600;

                @media screen and (max-width: 1399px) {
                  font-size: 14px;
                }

                @media screen and (max-width: 1199px) and (min-height: 1000px) {
                  font-size: 16px;
                }

                @media screen and (max-width: 991px) {
                  font-size: 14px;
                }
              }

              .highlight-text {
                font-size: 16px;
                line-height: normal;
                font-family: var(--font-plus-jakarta-sans) !important;
                font-weight: 400;

                @media screen and (max-width: 1399px) {
                  font-size: 13px;
                }

                @media screen and (max-width: 1199px) and (min-height: 1000px) {
                  font-size: 16px;
                }

                @media screen and (max-width: 991px) {
                  font-size: 12px;
                }

                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }

  .auth-right {
    .heading {
      color: var(--color-white);
      font-size: 34px;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 575px) {
        font-size: 26px;
      }
    }

    .main-form-section {
      background: #fff;
      border-radius: 40px;
      box-shadow: 0px 54.78px 109.56px -21.91px #32325d40;

      @media screen and (max-width: 1399px) {
        border-radius: 30px;
      }

      @media screen and (max-width: 1199px) and (min-height: 1000px) {
        border-radius: 36px;
      }

      @media screen and (max-width: 991px) {
        border-radius: 30px;
      }

      @media screen and (max-width: 575px) {
        border-radius: 26px;
      }

      .form-section {
        .form-heading {
          font-size: 22px;
          font-family: var(--font-plus-jakarta-sans);
          font-weight: 700;

          @media screen and (max-width: 1399px) {
            font-size: 20px;
          }

          @media screen and (max-width: 1199px) and (min-height: 1000px) {
            font-size: 22px;
          }

          @media screen and (max-width: 991px) {
            font-size: 20px;
          }

          &.basic-info-heading {
            margin-top: 1.5rem;

            @media screen and (max-width: 1199px) and (min-height: 1000px) {
              margin-top: 0.5rem;
            }

            @media screen and (max-width: 767px) {
              margin-top: 0.75rem;
              margin-bottom: 0.75rem;
            }
          }
        }

        .form-subheading {
          color: var(--color-primary-font);
          font-size: 16px;
          font-family: var(--font-plus-jakarta-sans);
          font-weight: 400;
          letter-spacing: unset;

          @media screen and (max-width: 1399px) {
            font-size: 14px;
            letter-spacing: -0.25px;
          }

          @media screen and (max-width: 1199px) and (min-height: 1000px) {
            font-size: 16px;
          }

          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }

        .form-input-label {
          font-size: 16px;
          margin-bottom: 0.5rem;
          font-family: var(--font-plus-jakarta-sans);
          font-weight: 500;

          @media screen and (max-width: 1399px) {
            font-size: 14px;
            margin-bottom: 0.2rem;
          }

          @media screen and (max-width: 1199px) and (min-height: 1000px) {
            font-size: 16px;
          }

          @media screen and (max-width: 991px) {
            font-size: 12px;
          }

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }

        .input-row {
          min-height: 8rem;

          @media screen and (max-width: 1399px) {
            min-height: 6.5rem;
          }

          @media screen and (max-width: 1199px) and (min-height: 1000px) {
            margin-bottom: 0;
            min-height: unset;
          }

          @media screen and (max-width: 767px) {
            margin-bottom: 0;
            min-height: unset;
          }

          &:first-child {
            margin-top: 0.75rem;

            @media screen and (max-width: 1399px) {
              margin-top: 0.5rem;
            }
          }

          .input-col-left {
            padding-left: 0.75rem;
          }

          .input-col-right {
            padding-right: 0.75rem;
          }

          .input-col-left,
          .input-col-right {
            @media screen and (max-width: 1199px) and (min-height: 1000px) {
              width: 100%;
              height: 6.5rem;
            }

            @media screen and (max-width: 991px) {
              height: 5.75rem;
            }

            @media screen and (max-width: 767px) {
              height: 6.75rem;
            }
          }
        }

        .input-icon-div {
          position: relative;
          height: fit-content;

          .auth-form-input {
            padding-left: 3rem;

            @media screen and (max-width: 1399px) {
              padding-left: 2.25rem;
            }

            @media screen and (max-width: 1199px) {
              padding-left: 1.75rem;
            }
          }

          .input-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 1rem;
            width: 1rem;
            height: 1rem;

            @media screen and (max-width: 1399px) {
              left: 0.5rem;
            }
          }
        }

        .auth-form-input {
          background-color: transparent !important;
          border: 1px solid var(--color-dark-disabled);
          border-radius: 10px;
          height: 45px;
          font-size: 14px;
          letter-spacing: -0.25px;

          @media screen and (max-width: 1399px) {
            height: 38px;
            font-size: 12px;
            padding: 0.375rem 0.75rem 0.375rem 0.25rem;
          }

          @media screen and (max-width: 1199px) and (min-height: 1000px) {
            font-size: 16px;
            height: 45px;
            border-radius: 6px;
          }

          @media screen and (max-width: 991px) {
            height: 32px;
            font-size: 12px;
          }

          @media screen and (max-width: 767px) {
            height: 44px;
            border-radius: 10px;
            font-size: 14px;
          }

          &.error-border {
            border: 1px solid #e10404;
          }
        }

        .auth-password-input {
          border-radius: 18px 0px 0px 18px;
          border-right: unset;
        }

        .svg {
          padding: 0.495vw 0.625vw;
          font-size: 0.729vw;
        }

        .svg-right {
          border-radius: 0 18px 18px 0;
          border: 0.052vw solid var(--color-dark-disabled);
          border-left: none;
          height: 45px;
        }

        .err {
          border-color: var(--color-danger) !important;
        }

        .mat-mdc-error {
          --mdc-checkbox-unselected-icon-color: var(--color-danger);
        }
      }
    }

    ::-webkit-input-placeholder {
      /* Edge */
      font-family: var(--font-plus-jakarta-sans);
      font-weight: 400;
      color: var(--color-disabled-gray);
    }

    :-ms-input-placeholder {
      /* Internet Explorer */
      font-family: var(--font-plus-jakarta-sans);
      font-weight: 400;
      color: var(--color-disabled-gray);
    }

    ::placeholder {
      font-family: var(--font-plus-jakarta-sans);
      font-weight: 400;
      color: var(--color-disabled-gray);
    }
  }

  .signup-auth-right {
    padding: 4.8vw 2.5vw 4.8vw 1.5vw;

    .heading {
      padding-right: 14vw;
    }

    .main-form-section {
      height: 31vw;

      &.main-form-section-err {
        height: 35vw;
      }

      .form-section {
        padding: 1.5vw 3vw;
      }

      .auth-checkbox {
        position: relative;
        left: -0.417vw;
      }
    }
  }

  .signin-auth-right {
    .main-form-section {
      height: 29vw;
    }

    .main-form-section-err {
      height: 31.5vw;
    }

    .height-10vw {
      height: 10vw;
    }
  }

  .forgot-password-auth-right {
    .mobile-number-border {
      width: 6.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: unset;
      padding-left: 0.75rem;
      border: unset;
      font-size: 14px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      @media screen and (max-width: 1199px) {
        width: 3.75rem;
        padding-left: 0;
      }

      @media screen and (max-width: 991px) {
        width: 7rem;
      }

      .mobile-selection {
        border: unset !important;
        border-radius: unset;
        border-right: 0.052vw solid var(--color-dark-disabled) !important;
        padding: unset;
        padding-right: 1rem;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 0.25rem;

        @media screen and (max-width: 1399px) {
          font-size: 12px;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          font-size: 16px;
        }

        @media screen and (max-width: 1199px) {
          padding-right: 0;
        }

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }

        &:focus {
          border-color: none;
          color: unset;
          background-color: unset;
        }

        &::after {
          content: '';
          width: 0.75rem;
          height: 0.5rem;
          background: url('/assets/images/auth/arrow-dropdown.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto;
          border: none;
        }
      }
    }

    .mobile-input {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border: unset !important;
      font-size: 14px;
      letter-spacing: -0.5;

      @media screen and (max-width: 1399px) {
        font-size: 12px;
      }

      @media screen and (max-width: 1199px) and (min-height: 1000px) {
        font-size: 16px;
      }

      @media screen and (max-width: 1199px) {
        padding-right: 0;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }

    .dropdown-menu {
      padding: 0.365vw 0.417vw;
      min-width: 100%;

      &.country-tel-code {
        min-width: 16rem !important;

        @media screen and (max-width: 767px) {
          min-width: 16.5rem !important;
        }
      }

      .search-icon-div {
        position: absolute;
        left: 4%;
        top: 50%;
        transform: translateY(-50%);

        @media screen and (max-width: 1399px) {
          width: 14px;
          height: 14px;
        }

        @media screen and (max-width: 575px) {
          left: 5%;
        }

        svg {
          vertical-align: middle;
          vertical-align: -webkit-baseline-middle;
        }
      }

      .search-form-input {
        padding-left: 2.5rem;
        border: 0.052vw solid var(--color-dark-disabled);
        height: 45px;
        border-radius: 10px;
        margin-bottom: 0.5rem;

        @media screen and (max-width: 1399px) {
          height: 36px;
          border-radius: 6px;
          font-size: 14px;
          padding-left: 1.75rem;
        }

        @media screen and (max-width: 991px) {
          padding-left: 2.5rem;
        }
      }

      .country-list {
        max-height: 215px;
        overflow-x: hidden;
        overflow-y: scroll;
        display: block;
        padding-right: 0.5rem;

        @media screen and (max-width: 767px) {
          padding-right: 0.75rem;
        }
      }
    }

    .mat-mdc-checkbox {
      &.mat-accent:not(.mat-mdc-error) {
        .mdc-checkbox__background {
          border-color: var(--color-primary) !important;
        }

        --mdc-checkbox-selected-checkmark-color: var(--color-primary) !important;
        --mdc-checkbox-selected-focus-icon-color: transparent !important;
        --mdc-checkbox-selected-hover-icon-color: transparent !important;
        --mdc-checkbox-selected-icon-color: transparent !important;
        --mdc-checkbox-selected-pressed-icon-color: transparent !important;
      }

      .mdc-checkbox--selected~.mdc-checkbox__ripple {
        background: var(--color-primary) !important;
      }
    }
  }

  .reset-password-auth-right {
    .main-form-section {
      height: 30vw;

      &.main-form-section-err {
        height: 33vw;
      }
    }

    .progress-bar {
      .progress-bar-item {
        height: 4px;
        background-color: #ddd;
      }

      .progress-bar-overlay {
        top: -0.208vw;
        height: 4px;
        position: relative;
        background-color: #ddd;
      }
    }
  }

  .otp-error {
    .otp-container {
      .otp-input-group {
        border: 1.5px solid #ffa6aa !important;

        .otp-input {
          &:not(:last-child) {
            border-right: 1.5px solid #ffa6aa !important;
          }
        }
      }
    }
  }

  .help-block {
    font-family: var(--font-plus-jakarta-sans);

    .notification-text {
      color: var(--color-success);
      font-family: var(--font-plus-jakarta-sans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 155.556%;

      @media screen and (max-width: 1399px) {
        font-size: 14px;
      }

      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
    }

    .small {
      font-size: 16px;
      font-family: var(--font-plus-jakarta-sans);
      font-weight: 400;

      @media screen and (max-width: 1399px) {
        font-size: 14px;
      }
    }
  }

  .terms-privacy-section {
    height: 7rem;

    @media screen and (max-width: 1199px) and (min-height: 1000px) {
      height: 10rem;
      margin-top: 0.5rem;
    }

    @media screen and (max-width: 991px) {
      height: 8.5rem;
      margin-top: 0.5rem;
    }

    .terms-privacy-parent {
      display: flex;
      gap: 2rem;
      margin-bottom: 0.75rem;

      .input-checkbox-div {
        input[type='checkbox'] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 16px;
          width: 16px;
          z-index: 12;

          &:checked~.check-mark {
            background: #1c1b1f;

            &::after {
              display: block;
            }

            &::before {
              background: #1c1b1f;
            }
          }
        }

        .check-mark {
          position: absolute;
          left: 0;
          background-color: #1c1b1f;
          border: 1px solid #1c1b1f;
          height: 16px;
          width: 16px;
          border-radius: 3px;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 12px;
            width: 12px;
            background: var(--color-navbar);
          }

          &::after {
            content: '';
            position: absolute;
            top: 45%;
            height: 10px;
            width: 4px;
            left: 25%;
            border: solid var(--color-white);
            border-width: 0 1px 1px 0;
            transform: rotate(45deg) translate(-50%, -50%);
            display: none;
          }

          &.error-checkbox {
            border: 1px solid var(--color-danger);
            background-color: var(--color-danger);
          }
        }
      }

      .checkbox-label {
        font-size: 16px;
        color: var(--color-primary-font);
        line-height: normal;
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 400;

        @media screen and (max-width: 1399px) {
          font-size: 14px;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          font-size: 16px;
        }

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }

        a {
          color: var(--color-primary);
        }
      }
    }
  }
}

.is-account-div {
  p {
    font-size: 16px;
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 500;

    @media screen and (max-width: 1399px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1199px) and (min-height: 1000px) {
      font-size: 16px;
    }

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      position: relative;
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: var(--color-primary-button);
    }
  }
}

.auth-btn {
  width: 100%;
  background-color: var(--color-primary-button);
  color: var(--color-white);
  border-radius: 30px;
  height: 50px;
  font-family: var(--font-plus-jakarta-sans);
  font-weight: 500;

  @media screen and (max-width: 1399px) {
    height: 42px;
  }

  @media screen and (max-width: 1199px) and (min-height: 1000px) {
    height: 50px;
  }

  @media screen and (max-width: 991px) {
    height: 42px;
  }

  @media screen and (max-width: 767px) {
    height: 44px;
  }

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  .btn-text {
    text-align: center;
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 400;
    font-size: 18px;

    @media screen and (max-width: 1399px) {
      font-size: 16px;
    }

    @media screen and (max-width: 1199px) and (min-height: 1000px) {
      font-size: 18px;
    }

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }

  &:disabled {
    background-color: var(--color-dark-disabled) !important;
    color: var(--color-disabled-gray) !important;
    border: none;
  }

  &:active {
    background-color: var(--color-primary-button);
    border-color: var(--color-primary-button);
    color: var(--color-white);
  }
}

// Common style for expired link and Signup success
.link-expired-right,
.signup-success-right {
  width: 660px;

  @media screen and (max-width: 1399px) {
    width: 520px;
  }

  @media screen and (max-width: 1199px) and (min-height: 1000px) {
    width: 470px;
  }

  @media screen and (max-width: 991px) {
    width: 350px;
  }

  .main-form-section {
    padding: 2rem 2.5rem;

    @media screen and (max-width: 1399px) {
      padding: 1.5rem;
    }

    .right-side-logo-div {
      width: 244px;
      height: 36px;

      @media screen and (max-width: 1399px) {
        width: 204px;
        height: 30px;
      }

      @media screen and (max-width: 767px) {
        width: 150px;
        height: 22px;
      }
    }

    .success-content-section {
      margin-top: 2rem;

      @media screen and (max-width: 1399px) {
        margin-top: 3rem;
      }

      @media screen and (max-width: 1199px) and (min-height: 1000px) {
        margin-top: 2rem;
      }

      h1 {
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 700;
        font-size: 32px;
        line-height: normal;

        @media screen and (max-width: 1399px) {
          font-size: 28px;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          font-size: 26px;
        }

        @media screen and (max-width: 991px) {
          font-size: 18px;
        }

        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }

      .body-text,
      .support-text {
        color: var(--color-primary-font);
        line-height: normal;
        font-size: 22px;

        @media screen and (max-width: 1399px) {
          font-size: 20px;
        }

        @media screen and (max-width: 1199px) {
          font-size: 18px;
        }

        @media screen and (max-width: 991px) {
          font-size: 16px;
          letter-spacing: -0.2px;
        }

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      .body-text {
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 500;
        opacity: 0.8;
        margin: 1.5rem 0;

        br {
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
      }

      .support-text {
        font-family: var(--font-plus-jakarta-sans);
        font-weight: 500;
        opacity: 0.5;
        margin-bottom: 0.5rem;
      }
    }
  }
}

//Common style for stepper details body
.sign-up-details-body {
  h2 {
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 1.5rem;
    color: var(--color-primary-font);

    @media screen and (max-width: 1399px) {
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 911px) {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  .company-details-form {
    .row {
      .right-padding {
        padding-right: 0.5rem;

        @media screen and (max-width: 1399px) {
          padding-right: 0.65rem;
        }
      }

      .left-padding {
        padding-left: 0.5rem;

        @media screen and (max-width: 1399px) {
          padding-left: 0.65rem;
        }
      }

      .right-padding,
      .left-padding {
        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          width: 100%;
          min-height: 7rem;
        }

        @media screen and (max-width: 991px) {
          min-height: 6rem;
        }

        @media screen and (max-width: 767px) {
          min-height: 7.25rem;
        }
      }
    }
  }
}

.error-message {
  color: var(--color-danger);
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 1;
  font-family: var(--font-plus-jakarta-sans);
  font-weight: 500;
  margin-top: 0.35rem;

  @media screen and (max-width: 1399px) {
    font-size: 14px;
    letter-spacing: -0.55px;
  }
}
