.lock-screen {
  @media (min-width: 1900px) {
    padding: 20px 30px !important;
  }

  @media screen and (max-width: 767px) {
    padding-top: 0 !important;
  }

  // Lock Screen Stepper
  .stepper-container-main {
    padding: 25px 30px !important;

    .btn-back-row {
      top: 0 !important;
    }
  }

  .travel-card-container {
    height: calc(100vh - 210px);

    .traveller-list-table {
      .t-body-overflow {
        height: 64vh !important;

        @media screen and (min-width: 1900px) {
          height: 62vh !important;
        }

        @media screen and (max-width: 1199px) {
          height: 60vh !important;
        }

        @media screen and (max-width: 1199px) and (min-height: 1000px) {
          height: 65vh !important;
        }

        @media screen and (max-width: 767px) {
          height: 42vh !important;
        }

        @media screen and (max-width: 575px) {
          height: 33vh !important;
        }
      }

      .t-grid-table-new {
        .t-head-bg {
          .t-box {
            .t-table-list-text {
              @media (min-width: 1900px) {
                font-size: 20px !important;
              }
            }
          }
        }
      }
    }

    .add-traveller-list-table {
      .add-traveller-inner-container {
        .add-traveler-card {
          .add-taveller-card-head {
            svg {
              @media (min-width: 1900px) {
                margin-right: 26px !important;
              }
            }

            .t-table-list-text {
              @media (min-width: 1900px) {
                font-size: 20px !important;
              }
            }
          }

          .add-traveller-card-text {
            @media (min-width: 1900px) {
              font-size: 18px !important;
            }
          }

          .excel-div {
            @media (min-width: 1900px) {
              max-width: 124px !important;
              height: 46px !important;
            }

            .minus-svg {
              @media (min-width: 1900px) {
                width: 73px !important;
                height: 73px !important;
              }
            }

            .plus-svg {
              @media (min-width: 1900px) {
                width: 73px !important;
                height: 73px !important;
              }
            }
          }
        }
      }

      .t-grid-table-new {
        .t-grid-table-wrapper {
          .t-box {
            .row-index {
              @media (min-width: 1900px) {
                font-size: 18px !important;
              }
            }
          }
        }
      }
    }
  }

  .plan-container-card {
    .plan-card-table-container {
      .t-grid-table-new {
        .t-grid-table-wrapper {
          grid-template-columns: 55px 2fr 1.5fr 1fr !important;

          @media screen and (min-width: 1900px) {
            grid-template-columns: 55px 2fr 1fr 1fr !important;
          }

          @media screen and (max-width: 1199px) {
            grid-template-columns: 15px 2fr 1.5fr 1fr !important;
          }

          .traveller-name-col {
            padding-right: 10rem !important;

            @media screen and (min-width: 1900px) {
              padding-right: 18rem !important;
            }

            @media screen and (max-width: 1199px) {
              padding-right: 3rem !important;
            }

            @media screen and (max-width: 575px) {
              padding-right: 0 !important;
            }
          }

          .sell-price-perent-container {
            padding-right: 3rem !important;

            @media screen and (min-width: 1900px) {
              padding-right: 1rem !important;
            }

            @media screen and (max-width: 1199px) {
              padding-right: 1rem !important;
            }
          }
        }
      }

      .t-body-overflow {
        height: 50.5vh !important;
        overflow: auto;

        @media (min-width: 1900px) {
          height: calc(100vh - 385px) !important;
        }

        @media screen and (max-width: 575px) {
          padding-right: 0.5rem !important;
        }
      }

      .selling-price-none-lock {
        display: none !important;
      }
    }

    .plans-brows-body-flex .cards {
      width: 300px !important;

      @media screen and (max-width: 1199px) {
        width: 198px !important;
      }

      @media screen and (max-width: 991px) {
        width: 220px;
      }

      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
  }

  .destination-container .country-row-container .country-row {
    height: calc(100vh - 360px) !important;

    @media (min-width: 1900px) {
      height: calc(100vh - 350px) !important;
    }
  }

  // Order Summary Section
  .order-summary-component {
    .order-container {
      .summary-table {
        .table-container {
          .summary-table-for-individual {
            .table {
              .table-body {
                height: 28vh !important;

                @media screen and (min-width: 1900px) {
                  height: 28vh !important;
                }

                @media screen and (max-width: 1199px) {
                  height: 26vh !important;
                }

                @media screen and (max-width: 991px) {
                  height: 23vh !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .payment-option-component {
    .order-container {
      .summary-table {
        max-height: 75vh !important;

        .table-container {
          .table-container-inner {
            height: 68vh !important;
          }
        }
      }
    }
  }
}
