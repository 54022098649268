// Styles v2.0

.head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;

  .head-text {
    color: var(--color-primary-font);
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 700;
    font-size: 22px;

    @media screen and (min-width: 1900px) {
      font-size: 26px;
    }

    @media screen and (max-width: 1199px) {
      font-size: 20px;
    }

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }

    @media screen and (max-width: 575px) {
      font-size: 26px;
    }
  }

  .btn-head {
    padding: 4px 26px;
    border-radius: 100px;
    background: var(--color-primary-button);
    color: var(--color-white);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    font-size: 18px;
    line-height: 28px;
  }
}

// Report graph styles

.report-graph-card {
  background: var(--color-navbar);
  padding: 15px 20px;
  box-shadow:
    1px 5px 11px 0px rgba(var(--color-shadow), 0.1),
    6px 19px 19px 0px rgba(var(--color-shadow), 0.09),
    13px 42px 26px 0px rgba(var(--color-shadow), 0.065),
    23px 74px 31px 0px rgba(var(--color-shadow), 0.03),
    35px 116px 34px 0px rgba(var(--color-shadow), 0);
  border-radius: 16px;
  margin-bottom: 25px;

  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
    padding: 15px;
  }

  .report-graph-card-header {
    color: var(--color-primary-font);
    font-family: var(--font-plus-jakarta-sans);
    font-weight: 700;
    font-size: 18px;
    line-height: normal;

    @media screen and (min-width: 1900px) {
      font-size: 20px;
    }

    @media screen and (max-width: 1199px) {
      font-size: 14px;
    }

    @media screen and (max-width: 991px) {
      font-size: 12px;
    }

    .sub-header {
      color: var(--color-primary-font);
      opacity: 0.5;
      margin-left: 20px;
      font-weight: 500;

      @media screen and (max-width: 1199px) {
        font-size: 12px;
      }

      @media screen and (max-width: 991px) {
        font-size: 10px;
      }
    }
  }

  .report-card-body {
    min-height: 200px;
    margin-top: 20px;

    @media (min-width: 1900px) {
      min-height: 260px;
    }
  }
}

// shadow

.ta-shadow {
  box-shadow:
    1px 5px 11px 0px rgba(var(--color-shadow), 0.1),
    6px 19px 19px 0px rgba(var(--color-shadow), 0.09),
    13px 42px 26px 0px rgba(var(--color-shadow), 0.065),
    23px 74px 31px 0px rgba(var(--color-shadow), 0.03),
    35px 116px 34px 0px rgba(var(--color-shadow), 0);
}

// pagination

.ngx-pagination {
  margin-top: 8px;
  margin-bottom: 5px !important;
}